import { createSlice } from '@reduxjs/toolkit';
import { Project } from '@shared/types/project.type';
import { ApiRequestStatus } from '@app/models/api/api-request-status.type';
import { ProjectCreateActions } from './project-create.actions';

interface createProjectState {
  projectData: Project | null;
  status: ApiRequestStatus;
  updateStatus: ApiRequestStatus;
}
const initialState: createProjectState = {
  projectData: null,
  status: 'idle',
  updateStatus: 'idle'
};
export const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(ProjectCreateActions.createProjectAttempt, state => ({
        ...state,
        projectData: null,
        status: 'loading'
      }))
      .addCase(ProjectCreateActions.createProjectSuccess, (state, action) => ({
        ...state,
        status: 'succeeded',
        projectData: action.payload.projectData
      }))
      .addCase(ProjectCreateActions.createProjectFailed, state => ({
        ...state,
        status: 'failed'
      }))

      // Update Project Actions
      .addCase(ProjectCreateActions.updateProjectAttempt, state => ({
        ...state,
        updateStatus: 'loading' // Set status to loading during update attempt
      }))
      .addCase(ProjectCreateActions.updateProjectSuccess, (state, action) => ({
        ...state,
        updateStatus: 'succeeded', // Set status to succeeded when update is successful
        projectData: action.payload.projectData // Update the project data with new details
      }))
      .addCase(ProjectCreateActions.updateProjectFailed, state => ({
        ...state,
        updateStatus: 'failed' // Set status to failed on update failure
      }))
});
