import { createAction } from '@reduxjs/toolkit';
import {
  Project,
  ProjectCreateRequestInput,
  ProjectUpdateRequestInput
} from '@shared/types/project.type';
import { ApiError } from '@app/models/api/api-response.interface';

const createProjectAttempt = createAction<ProjectCreateRequestInput>(
  '[Create Project] Create Project Attempt'
);

const createProjectSuccess = createAction<{
  projectData: Project;
}>('[Create Project] Create Project Success');

const createProjectFailed = createAction<{ error: ApiError }>(
  '[Create Project] Create Project Failed'
);

// Action to initiate the update project process
const updateProjectAttempt = createAction<ProjectUpdateRequestInput>(
  '[Update Project] Update Project Attempt'
);

// Action to handle successful update of a project
const updateProjectSuccess = createAction<{
  projectData: Project;
}>('[Update Project] Update Project Success');

// Action to handle failure during the update project process
const updateProjectFailed = createAction<{ error: ApiError }>(
  '[Update Project] Update Project Failed'
);

export const ProjectCreateActions = {
  createProjectAttempt,
  createProjectSuccess,
  createProjectFailed,
  updateProjectAttempt,
  updateProjectSuccess,
  updateProjectFailed
};
