import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from '../../../../providers/AppProvider';
import { Card } from 'react-bootstrap';
import Badge from '../../../../components/base/Badge';
import React from 'react';

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

// Define the type for a single data item
interface FairnessChartDataItem {
  Attribute: string; // x-axis value
  'Disparity Ratio': number; // y-axis value
}

// Define the props for the component
interface FairnessChartProps {
  data: FairnessChartDataItem[]; // Array of data items
  title: string;
  description: string;
}

const FairnessChart: React.FC<FairnessChartProps> = ({
  data,
  title,
  description
}) => {
  const { getThemeColor } = useAppContext();

  // Extract x-axis (Attributes) and y-axis (Disparity Ratios) values from the data
  const attributes = data.map(item => item.Attribute);
  const disparityRatios = data.map(item => item['Disparity Ratio']);

  // Chart options
  const options = {
    color: getThemeColor('primary'),
    tooltip: {
      trigger: 'axis',
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      formatter: (params: Array<{ name: string; value: number }>) => {
        const param = params[0];
        return `<strong>${param.name}:</strong> ${param.value}`;
      }
    },
    xAxis: {
      type: 'category',
      data: attributes,
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: getThemeColor('secondary-color'), rotate: 45 }
    },
    yAxis: {
      type: 'value',
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: getThemeColor('secondary-color') }
    },
    series: [
      {
        type: 'bar',
        data: disparityRatios,
        barWidth: '50%',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: getThemeColor('primary')
        }
      }
    ],
    grid: { left: '10%', right: '10%', bottom: '15%', top: '15%' }
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="mb-1">
                {title}
                <Badge bg="warning" variant="phoenix" pill className="ms-2">
                  -6.8%
                </Badge>
              </h4>
              <h6 className="text-body-tertiary">{description}</h6>
            </div>
          </div>
          <div className="d-flex justify-content-center px-4 py-6">
            <ReactEChartsCore
              echarts={echarts}
              option={options}
              style={{ height: '400px', width: '100%' }}
            />
          </div>
          <div className="mt-2">
            <div className="d-flex align-items-center mb-2">
              <div className="bullet-item bg-primary me-2"></div>
              <h6 className="text-body fw-semibold flex-1 mb-0">Completed</h6>
              <h6 className="text-body fw-semibold mb-0">52%</h6>
            </div>
            <div className="d-flex align-items-center">
              <div className="bullet-item bg-primary-subtle me-2"></div>
              <h6 className="text-body fw-semibold flex-1 mb-0">
                Pending payment
              </h6>
              <h6 className="text-body fw-semibold mb-0">48%</h6>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default FairnessChart;
