import { AxiosError } from 'axios';
import { CreateProjectResponse } from '@shared/types/project.type';
import {
  ApiError,
  AxiosApiResponse
} from '@app/models/api/api-response.interface';
import api from '../../../../../app/api/axios-api';

const createProject = async (
  userId: string,
  title: string,
  domain: string,
  overview: string
): Promise<AxiosApiResponse<CreateProjectResponse>> => {
  try {
    const response = await api.post(`project/create-project`, {
      userId,
      title,
      domain,
      overview
    });
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};

const updateProject = async (
  userId: string,
  projectId: string,
  updateData: { title?: string; domain?: string; overview?: string }
): Promise<AxiosApiResponse<CreateProjectResponse>> => {
  try {
    const response = await api.put(`project/update-project`, {
      userId,
      projectId,
      ...updateData
    });
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};
export const ProjectCreateService = {
  createProject,
  updateProject
};
