import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  FloatingLabel,
  Form,
  Nav,
  Row,
  Spinner,
  Tab
} from 'react-bootstrap';
import Button from '../../../components/base/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchActiveProject from '../../../hooks/useFetchActiveProject';
import InventoryTab from '../../../components/tabs/InventoryTab';
import { setToast } from '../../../components/Toasts/data-access/toast-reducer';
import { ProjectCreateActions } from './data-access/+state/project-create.actions';
import { ProjectCreationSelectors } from './data-access/+state/project-create.selectors';
import { Domain } from '@shared/types/project.type';
import axios from 'axios';
import { ProjectSelector } from '../project-list/data-access/+state/project-list.selectors';

const UpdateProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  // State for form inputs
  const [title, setTitle] = useState('');
  const [overview, setOverview] = useState('');
  const [domain, setDomain] = useState<Domain>('Banking');
  const [projectId, setProjectId] = useState<string | null>(null);

  const [isCompleteTriggered, setIsCompleteTriggered] = useState(false);
  const [activeApi, setActiveApi] = useState<string | null>('');
  useFetchActiveProject();
  const activeProject = useSelector(ProjectSelector.activeProject);

  const updateProjectResponse = useSelector(
    ProjectCreationSelectors.updateProjectResponse
  );

  const [activeTab, setActiveTab] = useState<string | null>('create');

  // Sync `activeProject` values with the state when it changes
  useEffect(() => {
    if (activeProject) {
      setProjectId(activeProject.projectId);
      console.log('ACTIVE PROJECT', activeProject);
      setTitle(activeProject.title || '');
      setOverview(activeProject.overview || '');
      setDomain(activeProject.domain || 'Banking');
    }
  }, [activeProject]);

  const onComplete = async () => {
    if (activeProject) {
      const requestPayload = {
        projectId: activeProject.projectId,
        userId: userId,
        domain: activeProject.domain
      };

      setIsCompleteTriggered(true);
      try {
        setActiveApi('Executing Bias Detection');
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/project/features/bias-detection`,
          requestPayload
        );

        setActiveApi('Executing Fairness Matrix');
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/project/features/fairness-matrix`,
          requestPayload
        );

        setIsCompleteTriggered(false);
        setActiveApi('');
        dispatch(
          setToast({
            show: true,
            title: 'Process Completed',
            message: 'All steps executed successfully'
          })
        );
        navigate(`/project/${activeProject.projectId}/details`);
      } catch (error) {
        console.error('Error during API execution:', error);
        setIsCompleteTriggered(false);
        setActiveApi(null);
        dispatch(
          setToast({
            show: true,
            title: 'Execution Failed',
            message: 'An error occurred while executing the process'
          })
        );
      }
    }
  };

  const onSubmit = () => {
    if (userId && projectId) {
      dispatch(
        ProjectCreateActions.updateProjectAttempt({
          projectId,
          userId,
          title,
          overview,
          domain
        })
      );
    } else {
      dispatch(
        setToast({
          show: true,
          title: 'Failed',
          message: 'Failed to fetch active project'
        })
      );
    }
  };

  useEffect(() => {
    if (updateProjectResponse === 'succeeded') {
      dispatch(
        setToast({ show: true, title: 'Project Created', message: 'Success' })
      );
      setActiveTab('uploads');
    } else if (updateProjectResponse === 'failed') {
      dispatch(
        setToast({ show: true, title: 'Failed', message: 'Failed to create' })
      );
    }
  }, [updateProjectResponse]);

  return (
    <div>
      <h2 className="mb-4">Create a project</h2>
      <Row>
        <Col xs={12} xl={10}>
          <Tab.Container
            id="basic-tabs-example"
            activeKey={activeTab ?? 'create'}
            onSelect={k => setActiveTab(k)}
          >
            <Nav variant="underline" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="create">Project Information</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="uploads">Uploads</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="create">
                <Row as="form" className="g-3 mb-6">
                  <Col sm={6} md={8}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Project title"
                    >
                      <Form.Control
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Project title"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={6} md={4}>
                    <FloatingLabel
                      controlId="floatingSelectTask"
                      label="Select Domain"
                    >
                      <Form.Select
                        value={domain}
                        onChange={e => setDomain(e.target.value as Domain)}
                      >
                        <option>Select Domain</option>
                        <option value="Banking">Banking</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Pharma">Pharma</option>
                        <option value="CPG">CPG</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>

                  <Col xs={12} className="gy-6">
                    <FloatingLabel
                      controlId="floatingProjectOverview"
                      label="Project overview"
                    >
                      <Form.Control
                        as="textarea"
                        value={overview}
                        onChange={e => setOverview(e.target.value)}
                        placeholder="Leave a comment here"
                        style={{ height: '100px' }}
                      />
                    </FloatingLabel>
                  </Col>

                  <Col xs={12} className="gy-6">
                    <div className="d-flex justify-content-end gap-3">
                      <Button variant="phoenix-primary" className="px-5">
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmit}
                        variant="phoenix-primary"
                        className="px-5"
                      >
                        {updateProjectResponse === 'loading' ? (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          'Update'
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="uploads">
                <InventoryTab />
                <div className="d-flex justify-content-end gap-3 mt-3">
                  <Button variant="phoenix-primary" className="px-5">
                    Skip
                  </Button>
                  <Button
                    variant="phoenix-primary"
                    className="px-5"
                    onClick={onComplete}
                  >
                    Complete
                  </Button>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isCompleteTriggered}
      >
        <CircularProgress color="inherit" />
        <p>{activeApi}</p>
      </Backdrop>
    </div>
  );
};

export default UpdateProject;
