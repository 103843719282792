import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilCube
  // UilDocumentLayoutRight,
  // UilFilesLandscapesAlt,
  // UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

const userId = localStorage.getItem('userId');

export const routes: RouteItems[] = [
  {
    label: 'apps',
    icon: UilCube,
    pages: [
      {
        name: 'project-management',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'create new',
            path: `/project/${userId}/create-project`,
            pathName: 'project-management-create-new',
            active: true
          },
          {
            name: 'All-projects',
            path: `/project/${userId}/all-projects`,
            pathName: 'project-test-project-list-view',
            active: true
          }
        ]
      }
    ]
  }
];
