import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Project } from '@shared/types/project.type';
import { ProjectCreateService } from '../services/project-create.service';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ProjectCreateActions } from './project-create.actions';
import {
  ApiError,
  AxiosApiResponse
} from '@app/models/api/api-response.interface';
import { ProjectListActions } from '../../../project-list/data-access/+state/project-list.actions';

function* createProject(
  action: PayloadAction<{
    userId: string;
    title: string;
    domain: string;
    overview: string;
  }>
): Generator<unknown, void, AxiosApiResponse<Project>> {
  try {
    const { title, domain, overview, userId } = action.payload;
    const { response } = yield call(
      ProjectCreateService.createProject,
      userId,
      title,
      domain,
      overview
    );
    console.log('Full response:', response);
    if (response && response && response.data) {
      console.log('Response data:', response.data);
      yield put(
        ProjectCreateActions.createProjectSuccess({
          projectData: response.data
        })
      );
      yield put(
        ProjectListActions.setActiveProject({ activeProject: response.data })
      );
    } else {
      yield put(
        ProjectCreateActions.createProjectFailed({
          error: { error: 'Failed', status: 400, message: 'Failed' }
        })
      );
    }
  } catch (error) {
    // Cast the error to AxiosError<ApiError>
    const axiosError = error as AxiosError<ApiError>;

    const apiError: ApiError = {
      error: axiosError.response?.data?.error || 'Unknown error',
      message: axiosError.response?.data?.message || 'An error occurred',
      status: axiosError.response?.status || 500
    };

    yield put(
      ProjectCreateActions.createProjectFailed({
        error: apiError
      })
    );
  }
}

function* updateProject(
  action: PayloadAction<{
    userId: string;
    projectId: string;
    title?: string;
    domain?: string;
    overview?: string;
  }>
): Generator<unknown, void, AxiosApiResponse<Project>> {
  try {
    const { userId, projectId, title, domain, overview } = action.payload;

    const { response } = yield call(
      ProjectCreateService.updateProject, // Call the update service
      userId,
      projectId,
      { title, domain, overview }
    );

    console.log('Full response:', response);

    if (response && response.data) {
      console.log('Response data:', response.data);
      yield put(
        ProjectCreateActions.updateProjectSuccess({
          projectData: response.data
        })
      );

      // Optionally update the active project in the list
      yield put(
        ProjectListActions.setActiveProject({ activeProject: response.data })
      );
    } else {
      yield put(
        ProjectCreateActions.updateProjectFailed({
          error: { error: 'Failed', status: 400, message: 'Failed' }
        })
      );
    }
  } catch (error) {
    // Handle the error as AxiosError<ApiError>
    const axiosError = error as AxiosError<ApiError>;

    const apiError: ApiError = {
      error: axiosError.response?.data?.error || 'Unknown error',
      message: axiosError.response?.data?.message || 'An error occurred',
      status: axiosError.response?.status || 500
    };

    yield put(
      ProjectCreateActions.updateProjectFailed({
        error: apiError
      })
    );
  }
}

function* createProjectSaga() {
  yield takeLatest(
    ProjectCreateActions.createProjectAttempt.type,
    createProject
  );
  yield takeLatest(
    ProjectCreateActions.updateProjectAttempt.type,
    updateProject
  );
}
export default createProjectSaga;
