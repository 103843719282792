import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import BiasChart from './FairnessChart';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../../project-list/data-access/+state/project-list.selectors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Define the interface for fairness data
interface FairnessDataItem {
  Attribute: string;
  'Disparity Ratio': number;
  'Equal Opportunity': number;
  Risk: string;
}

export const FairnessCard = () => {
  const [mlFairnessData, setMlFairnessData] = useState<FairnessDataItem[]>([]);
  const [llmFairnessData, setLlmFairnessData] = useState<FairnessDataItem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const activeProject = useSelector(ProjectSelector.activeProject);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId && activeProject) {
      const fetchFairnessData = async () => {
        setIsLoading(true); // Show loading indicator

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/project/features/${activeProject?.projectId}/fairness-matrix`
          );

          const data = response.data;

          if (data) {
            setMlFairnessData(data.fairness_data_ml || []);
            setLlmFairnessData(data.fairness_data_llm || []);
          }
        } catch (error) {
          console.error('Error fetching fairness data:', error);
        } finally {
          setIsLoading(false); // Hide loading indicator
        }
      };

      fetchFairnessData();
    }
  }, [activeProject, userId]);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Row className="g-3 mt-4">
          <Col xs={12} md={6}>
            <BiasChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by\n' +
                '                analyzing sensitive attributes.'
              }
              title={'Fairness - ML'}
              data={mlFairnessData}
            />
          </Col>
          <Col xs={12} md={6}>
            <BiasChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by\n' +
                '                analyzing sensitive attributes.'
              }
              title={'Fairness - LLM'}
              data={llmFairnessData}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
