import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './../../../../app/shared/store';
import { accountSlice } from './account.reducer';

const selectUserSlice = (state: RootState) => state[accountSlice.name];

const userResponse = createSelector(selectUserSlice, state => ({
  status: state.status,
  userData: state.userData
}));
const createUserResponse = createSelector(selectUserSlice, state => ({
  status: state.status,
  userId: state.userId
}));

const createUserRequestStatus = createSelector(
  selectUserSlice,
  state => state.status
);

export const UserSelector = {
  userResponse,
  createUserRequestStatus,
  createUserResponse
};
