import { Dropdown } from 'react-bootstrap';
import useFetchActiveProject from '../../hooks/useFetchActiveProject';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../../pages/project-management/project-list/data-access/+state/project-list.selectors';

const ActionDropdownItems = () => {
  useFetchActiveProject();
  const activeProject = useSelector(ProjectSelector.activeProject);
  return (
    <>
      <Dropdown.Item
        href={`/project/${activeProject?.projectId}/update-project`}
        eventKey="1"
      >
        Upload Pending
      </Dropdown.Item>
      <Dropdown.Item eventKey="1">Analysis</Dropdown.Item>
      <Dropdown.Item eventKey="2">Report</Dropdown.Item>
      <Dropdown.Item eventKey="2">Dashboard</Dropdown.Item>
    </>
  );
};

export default ActionDropdownItems;
