import { FileUploadInput } from '@shared/types/project.type';

export const uploadFile = (
  input: FileUploadInput,
  onProgress: (progress: number, status: string) => void // Added status parameter
): Promise<never> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    let END_POINT = '/upload-dataset';
    switch (input.uploadType) {
      case 'DATA_SET':
        END_POINT = '/upload-dataset';
        break;
      case 'POLICY_DOCUMENT':
        END_POINT = '/upload-policy-document';
        break;
      case 'ML_MODEL':
        END_POINT = '/upload-ml-model';
        break;
      case 'LLM_MODEL':
        END_POINT = '/upload-llm-sensitive-output';
        break;
      default:
        END_POINT = '/upload-dataset';
        break;
    }

    // Append form data
    formData.append('file', input.file);
    if (input.domain) {
      formData.append('domain', input.domain);
    }
    formData.append('uploadType', input.uploadType ?? '');
    if (input.targetColumn) {
      formData.append('targetColumn', input.targetColumn);
    }
    formData.append('projectId', input.projectId);
    formData.append('userId', input.userId);

    xhr.open('POST', `${process.env.REACT_APP_API_BASE_URL}/${END_POINT}`);

    // Phase 1: File Upload Progress
    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const uploadProgress = Math.round((event.loaded * 50) / event.total); // File upload contributes 50% to the total progress
        onProgress(uploadProgress, 'Uploading');
      }
    };

    // Phase 2: API Processing Progress
    xhr.onloadstart = () => {
      onProgress(50, 'Processing'); // After upload completes, update to 50% progress
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        onProgress(100, 'Completed'); // Update to 100% when API call succeeds
        resolve(xhr.response);
      } else {
        onProgress(100, 'Failed'); // Handle failure after processing
        reject(new Error(xhr.statusText));
      }
    };

    xhr.onerror = () => {
      onProgress(100, 'Failed'); // Update progress to failed
      reject(new Error('Upload failed'));
    };

    xhr.send(formData);
  });
};
