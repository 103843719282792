import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { UserAccountActions } from './account.actions';
import { AxiosApiResponse } from '@app/models/api/api-response.interface';
import { UserResponse } from '@shared/types/user.types';
import { UserAccountService } from '../service/account-service';

function* fetchUserAccountAttempt(
  action: PayloadAction<
    ReturnType<typeof UserAccountActions.fetchUserAccountAttempt>['payload']
  >
): Generator<unknown, void, AxiosApiResponse<UserResponse>> {
  const { response } = yield call(
    UserAccountService.fetchUserAccount,
    action.payload.loginInput
  );
  console.log('RESPONSE', response?.data);
  if (response && response.data) {
    localStorage.setItem('userId', response.data?.userId);
    localStorage.setItem('accessToken', response.data.accessToken);
    yield put(
      UserAccountActions.fetchUserAccountSuccess({
        userData: response.data
      })
    );
  } else {
    yield put(UserAccountActions.fetchUserAccountFailed());
  }
}

function* createUserAccount(
  action: PayloadAction<
    ReturnType<typeof UserAccountActions.createUserAccountAttempt>['payload']
  >
): Generator<unknown, void, AxiosApiResponse<UserResponse>> {
  const { response } = yield call(
    UserAccountService.createAccount,
    action.payload.createUserInput
  );
  console.log('RESPONSE', response?.data);
  if (response) {
    yield put(
      UserAccountActions.createUserAccountSuccess({
        userResponse: response.data
      })
    );
  } else {
    yield put(UserAccountActions.createUserAccountFailed());
  }
}

function* userAccountSaga() {
  yield takeLatest(
    UserAccountActions.createUserAccountAttempt.type,
    createUserAccount
  );
  yield takeLatest(
    UserAccountActions.fetchUserAccountAttempt.type,
    fetchUserAccountAttempt
  );
}

export default userAccountSaga;
