import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ProjectListActions } from '../pages/project-management/project-list/data-access/+state/project-list.actions';
import { Project } from '@shared/types/project.type';

const useFetchActiveProject = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId && projectId) {
      const fetchProject = async () => {
        try {
          const response = await axios.get<Project>(
            `${process.env.REACT_APP_API_BASE_URL}/project/${userId}/project/${projectId}`
          );

          if (response.data) {
            // Dispatch the action to set the active project
            dispatch(
              ProjectListActions.setActiveProject({
                activeProject: response.data
              })
            );
          }
        } catch (error) {
          console.error('Error fetching active project:', error);
        }
      };

      fetchProject();
    }
  }, [userId, projectId, dispatch]);
};

export default useFetchActiveProject;
