import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../project-list/data-access/+state/project-list.selectors';
import PhoenixDocCard from '../../../components/base/PhoenixDocCard';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Table } from 'react-bootstrap';
import useFetchActiveProject from '../../../hooks/useFetchActiveProject';
import Badge from '../../../components/base/Badge';

// Define the type for Fairness data items
interface FairnessDataItem {
  Attribute: string;
  'Disparity Ratio': number;
  'Equal Opportunity'?: number;
  Risk?: string;
  [key: string]: string | number | undefined; // For additional optional fields
}

const FairnessMatrix = () => {
  const [mlBiasData, setMlBiasData] = useState<FairnessDataItem[]>([]);
  const [llmBiasData, setLlmBiasData] = useState<FairnessDataItem[]>([]);
  const [mlHeaders, setMlHeaders] = useState<string[]>([]);
  const [llmHeaders, setLlmHeaders] = useState<string[]>([]);
  const activeProject = useSelector(ProjectSelector.activeProject);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');

  useFetchActiveProject();

  useEffect(() => {
    if (userId && activeProject) {
      setIsLoading(true);
      const fetchFairnessData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/project/features/${activeProject?.projectId}/fairness-matrix`
          );

          const data = response.data;

          if (data) {
            const mlData = data.fairness_data_llm || [];
            const llmData = data.fairness_data_ml || [];

            setMlBiasData(mlData);
            setLlmBiasData(llmData);

            // Extract headers from the first item of each dataset
            if (mlData.length > 0) {
              setMlHeaders(Object.keys(mlData[0]));
            }
            if (llmData.length > 0) {
              setLlmHeaders(Object.keys(llmData[0]));
            }
          }
        } catch (error) {
          console.error('Error fetching fairness matrix data:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchFairnessData();
    }
  }, [activeProject, userId]);

  const renderTableHeaders = (headers: string[]) =>
    headers.map((header, index) => <th key={index}>{header}</th>);

  const getBadgeBg = (risk: string) => {
    switch (risk.toLowerCase()) {
      case 'low':
        return 'success';
      case 'moderate':
        return 'warning';
      case 'high':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  const renderTableRows = (data: FairnessDataItem[], headers: string[]) =>
    data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {headers.map((header, colIndex) => (
          <td key={colIndex}>
            {header === 'Risk' ? (
              <Badge
                bg={getBadgeBg(row[header] as string)}
                variant="phoenix"
                iconPosition="end"
                className="fs-10"
              >
                {row[header]}
              </Badge>
            ) : typeof row[header] === 'number' ? (
              (row[header] as number).toFixed(2) // Type assertion to number
            ) : (
              String(row[header])
            )}
          </td>
        ))}
      </tr>
    ));

  return (
    <div>
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Fairness Metric – Disparate Impact ML Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(mlHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(mlBiasData, mlHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Fairness Metric – Disparate Impact LLM Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(llmHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(llmBiasData, llmHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>
      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default FairnessMatrix;
