import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import BiasChart from './BiasChart';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../../project-list/data-access/+state/project-list.selectors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Define the type for bias data items
interface BiasDataItem {
  Attribute: string;
  'Disparity Ratio': number;
  'Calculated Impact'?: number;
  'Bias Detected'?: boolean;
  'External Compliance'?: string;
  'Internal Policy Compliance'?: string;
  Risk?: string;
  Action?: string;
  'Bias Drift'?: string;
  'Intersectional Bias'?: string;
}

export const BiasCard = () => {
  const [mlBiasData, setMlBiasData] = useState<BiasDataItem[]>([]);
  const [llmBiasData, setLlmBiasData] = useState<BiasDataItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const activeProject = useSelector(ProjectSelector.activeProject);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId && activeProject) {
      const fetchBiasData = async () => {
        setIsLoading(true); // Show loading indicator
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/project/features/${activeProject.projectId}/bias-detection`
          );

          const data = response.data;

          if (data) {
            setMlBiasData(data.ml_bias_data || []);
            setLlmBiasData(data.llm_bias_data || []);
          }
        } catch (error) {
          console.error('Error fetching bias detection data:', error);
        } finally {
          setIsLoading(false); // Hide loading indicator
        }
      };

      fetchBiasData();
    }
  }, [activeProject, userId]);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Row className="g-3">
          <Col xs={12} md={6}>
            <BiasChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by\n' +
                '                analyzing sensitive attributes.'
              }
              title={'BIAS - ML'}
              data={mlBiasData}
            />
          </Col>
          <Col xs={12} md={6}>
            <BiasChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by\n' +
                '                analyzing sensitive attributes.'
              }
              title={'BIAS - LLM'}
              data={llmBiasData}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
